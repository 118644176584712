import { useEffect, useState } from 'react';
import './gallery.scss';


const Gallery = () => {
  
  return (
    <div id="gallery">
      <div className='container'>
        <h3 className='title'>Vieni a trovarci su instagram</h3>
        <div class="elfsight-app-be1bfa75-f40b-4e4a-ac31-f693ff7c8714" data-elfsight-app-lazy></div>
        <div className='gallery container'>
        
        </div>
      </div>
    </div>
  );
  
}
const script = document.createElement("script");

  script.src = "https://static.elfsight.com/platform/platform.js";
  script.async = true;

  document.body.appendChild(script);

export default Gallery;